body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}


.container {
  display: grid;
  grid-gap: 1rem;
  margin-left: 10%;
  margin-right: 10%;
  grid-template-areas:
      "skills ed"
      "work projects"
}

.header {
  border-radius: 25px;
  border: 2px solid #3b3939;
  width: 80%;
  margin: auto;
}

@media (max-width: 800px) {
  .header {
    border-radius: 25px;
    border: 2px solid #3b3939;
    width: 100%;
    margin: auto;
  }
  .container {
    margin-left: 5%;
    margin-right: 5%;
    grid-template-columns: 1fr;
    grid-template-areas:
      "skills"
      "ed"
      "work"
      "projects";
  }
}

app-header {
  grid-area: header;
}
app-work-experience{
  grid-area: work;
}
app-education{
  grid-area: ed;
}
app-skills{
  grid-area: skills;
}
app-projects{
  grid-area: projects;
}

h1.title {
  display: inline;
  vertical-align: top;
  padding-left: 10px;
}

h3.title {
  display: inline;
  margin-top: 5ch;
  margin-left: 2ch;
}

h5.title {
  display: inline;
  margin-top: 5ch;
  margin-left: 2ch;
}

.segmentBody {
  padding: 20px;
}


